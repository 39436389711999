<template>
  <div class="CookiesStyler">
    <CookiesNav v-if="$store.state.UserManagment.Status" :Items="NavItems"></CookiesNav>
    <div dir="ltr" id="MainAppContainer" class="CookiesApp COOKIESAPPVUE" :class="[$store.state.Printing !== '' && 'PrintingHider', $store.state.CookiesConfig.background && $store.state.CookiesConfig.background]">
      <div v-if="$store.state.UserManagment.Status" class="CookiesMainContainer" :style="(($store.state.UserManagment.CurrentUser.UserType.toLowerCase() === 'admin' || (Access('Navigation') && !Access('Driver')))) ? '' : 'margin-left: 0em;'" :class="$store.state.Printing !== '' && 'PrintingHider'">
        <router-view/>
        <div class="W-100" style="height: 5em;"></div>
      </div>
      <template v-if="!$store.state.UserManagment.Status">
        <div style="margin-top: 2em; left: 0; flex-direction: column;" class="W-100 D-Flex P-Abs">
          <div class="M-Auto"><div style="width:22em; height: 22em; max-width: 100%; background-size: contain; background-repeat:no-repeat; background-position: center;" :style="'background-image: url(' + $store.state.CookiesConfig.Logo + ');'"></div></div>
          <div class="M-Auto F1_5em" style="margin-top: 3em;"><CookiesLoading></CookiesLoading></div>
        </div>
        <CookiesSignIn v-if="!AutoSignIn" @SignedIn="CookiesAuthorization()" class="noprint"></CookiesSignIn>
        <div :class="$store.state.CookiesConfig.NoCopyRights ? 'D-None' : ''" class="CookiesCopyrights Shadow1">
          <div class="color3" style="font-size: 0.8em; margin: auto 0em;">
            powered by:
          </div>
          <a class="D-Flex" href="https://cookiesbis.com/" target="_blank">
            <img style="height: 70%; margin: auto 1em;" :src="$store.state.CookiesConfig.Theme === '1' ? require('@/assets/defaults/DarkCookies.svg') : require('@/assets/defaults/MilkCookies.svg')" alt="">
          </a>
        </div>
      </template>
    </div>
    <component v-bind:is="'CookiesDialog'"></component>
  </div>
  <CookiesDomPrint v-if="$store.state.Printing !== ''"></CookiesDomPrint>
</template>

<script>
import CookiesNav from '@/components/CookiesNav.vue'
import CookiesSignIn from '@/components/CookiesSignIn.vue'
import CookiesDomPrint from '@/components/CookiesDomPrint.vue'

export default {
  components: {
    CookiesNav,
    CookiesSignIn,
    CookiesDomPrint
  },
  data () {
    return {
      AutoSignIn: true,
      Configed: false
    }
  },
  created () {
    this.ReadConfig()
    if (!this.$store.state.UserManagment.Status) {
      this.CookiesAuthorization()
    }
    try {
      if (this.$store.state.UserManagment.CurrentUser.UserType.toLowerCase() !== 'admin' && this.Access('Driver')) {
        this.$router.replace('/DriverPortal')
      }
    } catch (error) {
    }
  },
  beforeMount () {
    this.TimeBeforeMounted()
  },
  methods: {
    CookiesAuthorization () {
      this.$store.state.UserManagment.CurrentUser = {}
      this.$store.state.UserManagment.Status = false
      switch (this.AuthorizationVersion) {
        case 2:
          this.Post('POST', ['Authorization'], {}).then(response => {
            this.Console('Authorization')
            this.Console(response)
            this.$store.state.UserManagment.CurrentUser.Email = 'System'
            // this.$store.state.UserManagment.CurrentUser = response
            var AccessList = {}
            try {
              response.RoleName.forEach(element => {
                AccessList[element.trim()] = true
              })
            } catch (error) {
            }
            this.$store.state.UserManagment.CurrentUser.AccessList = AccessList
            this.$store.state.UserManagment.Status = true
          }, error => {
            console.log(error)
            this.AutoSignIn = false
          })
          break
        case 1:
          this.Get(['Authorization']).then(response => {
            // if (response.Email.toLowerCase() === 'system') {
            //   this.LogOut()
            //   return
            // }
            this.$store.state.UserManagment.CurrentUser = response
            var AccessList = {}
            response.RoleName.forEach(element => {
              AccessList[element.trim()] = true
            })
            this.$store.state.UserManagment.CurrentUser.AccessList = AccessList
            this.$store.state.UserManagment.Status = true
            try {
              if (this.$store.state.UserManagment.CurrentUser.UserType.toLowerCase() !== 'admin' && this.Access('Driver')) {
                this.$router.replace('/DriverPortal')
              }
            } catch (error) {
            }
          }, error => {
            console.log(error)
            this.AutoSignIn = false
          })
          break
        default:
          break
      }
    }
  },
  computed: {
    TabHeaderShadower () {
      return this.$store.state.CookiesConfig.Theme === '1' ? 'rgba(0, 0, 0, 0.20)' : 'rgba(0, 0, 0, 0.17)'
    }
  },
  watch: {
    '$route' (to, from) {
      if (!this.$store.state.UserManagment.Status) {
        this.CookiesAuthorization()
      }
      try {
        if (this.$store.state.UserManagment.CurrentUser.UserType.toLowerCase() !== 'admin' && this.Access('Driver') && to.name !== 'DriverPortal') {
          this.$router.replace('/DriverPortal')
          return
        }
      } catch (error) {
      }
      try {
        if ((this.$store.state.UserManagment.CurrentUser.UserType.toLowerCase() === 'admin') && to.name === 'DriverPortal') {
          this.$router.replace('/')
          return
        }
      } catch (error) {
      }
      const CurrentDialogs = this.$store.state.CookiesDialog.OpenedDialogs
      CurrentDialogs.forEach(dialog => {
        if (to.query['Dialog' + dialog.Num] === undefined) {
          this.CloseDialog(dialog.Num)
        }
      })
      const CurrentQuery = JSON.parse(JSON.stringify(to.query))
      const DialogsNums = this.$store.state.CookiesDialog.OpenedDialogs.map(function (item) {
        return item.Num
      })
      Object.keys(CurrentQuery).some(function (attr) {
        if (DialogsNums.find(element => element.toString() === CurrentQuery[attr].toString()) === undefined) {
          delete CurrentQuery[attr]
        }
      })
      this.$router.push({ query: CurrentQuery })
    }
  }
}
</script>
<style>
@media print {
  @page {size: var(--A4L)}
}

#CookiesApp {
  font-family: 'Noto Kufi Arabic', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  overflow: hidden;
}
.CookiesMainContainer{
  direction: v-bind('$store.getters.Direction');
  height: 100%;
}
@media (min-width: 700px) and (hover: hover) {
  .CookiesMainContainer{
    margin-left: 4em;
  }
}
@media (max-width: 700px) {
  .CookiesCopyrights{
    font-size: 2.6vw !important;
  }
}
.COOKIESAPPVUE{
  top: 0;
  padding-top: 58px;
  direction: ltr;
}

@media (max-width: 700px) , (hover: none) {
  .COOKIESAPPVUE{
    padding-top: 32px !important;
  }
}
.CookiesApp {
  text-decoration: none;
}
.CookiesApp{
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: -50;
  color: v-bind('$store.getters.Theme.color1');
  overflow: scroll;
}
.CookiesCommentBox{
  color: v-bind('$store.getters.Theme.color4');
  background: v-bind('$store.getters.Theme.InputColor') !important;
}
.DefaultDirection{
  direction: v-bind('$store.getters.Direction');
}
.DefaultTextAlign{
  text-align: v-bind('$store.getters.DefaultTextAlign');
}
.colorMain{
  color: v-bind('$store.getters.Theme.UserColors.main');
}
.colorSecond{
  color: v-bind('$store.getters.Theme.UserColors.second');
}
.colorThird{
  color: v-bind('$store.getters.Theme.UserColors.third');
}
.colorFourth{
  color: v-bind('$store.getters.Theme.UserColors.fourth');
}
.color1{
  color: v-bind('$store.getters.Theme.color1');
}
.color2{
  color: v-bind('$store.getters.Theme.color2');
}
.color3{
  color: v-bind('$store.getters.Theme.color3');
}
.BG1{
  background-color: v-bind('$store.getters.Theme.BG1');
}
.BG2{
  background-color: v-bind('$store.getters.Theme.BG2');
}
.BG3{
  background-color: v-bind('$store.getters.Theme.BG3');
}
.BG4{
  background-color: v-bind('$store.getters.Theme.BG4');
}
.BG5{
  background-color: v-bind('$store.getters.Theme.BG5');
}
.BG6{
  background-color: v-bind('$store.getters.Theme.BG6');
}
.BG7{
  background-color: v-bind('$store.getters.Theme.BG7');
}
.BG8{
  background-color: v-bind('$store.getters.Theme.BG8');
}
.BG1Fixed{
  background-color: v-bind('$store.getters.Theme.BG1Fixed');
}
.BG2Fixed{
  background-color: v-bind('$store.getters.Theme.BG2Fixed');
}
.BG3Fixed{
  background-color: v-bind('$store.getters.Theme.BG3Fixed');
}
.BG4Fixed{
  background-color: v-bind('$store.getters.Theme.BG4Fixed');
}
.BG5Fixed{
  background-color: v-bind('$store.getters.Theme.BG5Fixed');
}
.BG6Fixed{
  background-color: v-bind('$store.getters.Theme.BG6Fixed');
}
.BG7Fixed{
  background-color: v-bind('$store.getters.Theme.BG7Fixed');
}
.BG8Fixed{
  background-color: v-bind('$store.getters.Theme.BG8Fixed');
}
.BGInput{
  background-color: v-bind('$store.getters.Theme.InputColor')
}
.InputColor{
  background-color: v-bind('$store.getters.Theme.InputColor');
}
.BGMain{
  background-color: v-bind('$store.getters.Theme.UserColors.main');
}
.BGSecond{
  background-color: v-bind('$store.getters.Theme.UserColors.second');
}
.BGThird{
  background-color: v-bind('$store.getters.Theme.UserColors.third');
}
.BGDimmed{
  background-color: v-bind('$store.getters.Theme.UserColors.Dimmed');
}
.BGGradient{
  background: v-bind('$store.getters.Theme.UserColors.Gradient');
}
.BGShade0{
  background-color: v-bind('$store.getters.Theme.Shade0');
}
.BGShade1{
  background-color: v-bind('$store.getters.Theme.Shade1');
}
.BGShade2{
  background-color: v-bind('$store.getters.Theme.Shade2');
}
.BGShade3{
  background-color: v-bind('$store.getters.Theme.Shade3');
}
.BGShade4{
  background-color: v-bind('$store.getters.Theme.Shade4');
}
.BGGreen{
  background-color: var(--colorg2);
}
.BGYellow{
  background-color: var(--colory1);
}
.BGRed{
  background-color: var(--colorr1);
}
.BGRed2{
  background-color: var(--colorr2);
}
.EditableCell{
  outline: none;
}
.EditableCell:focus{
  background: v-bind('$store.getters.Theme.UserColors.main_25');
  box-shadow: inset 1px 1px 5px rgba(228, 228, 228, 0.322);
}
.EditableCell:hover{
  background: v-bind('$store.getters.Theme.UserColors.main_25');
}
button{
    box-shadow: inset 1pt 1pt 10pt rgba(255,255,255,0.3);
}
.ActiveButton:hover{
  filter: saturate(1.5) drop-shadow(0.2em 0.2em 0.2em rgba(0,0,0,0.4)) brightness(1.5);
}
.CookiesInput{
    /* height: 1.8em; */
    outline: solid 0em transparent;
    border: 1px solid rgba(120,120,120,0);
    border-radius: var(--rad2);
    background: v-bind('$store.getters.Theme.InputColor');
    flex: auto;
    padding: 0.7em 1.2em;
    outline: none;
    margin: 0.5em;
    transition-property: all;
    transition-duration: 0.4s;
    text-align: v-bind('$store.getters.DefaultTextAlign');
    direction: auto !important;
    outline: 1px solid transparent;
}
.CookiesInput:focus{
  outline: 1px solid v-bind('$store.getters.Theme.UserColors.third_50') !important;
}
.CookiesInputDisabled{
    opacity: 0.3;
    pointer-events: none;
}
.CookiesInput::placeholder{
    opacity: 0.25;
    color: v-bind('$store.getters.Theme.color3');
}
.HoverGlow:hover{
  /* filter: drop-shadow(0 0 0.3em v-bind('$store.getters.Theme.Shade4')); */
  transform: translateY(-0.2em);
}
.HoverGlow{
  cursor: pointer;
}

/* ToolTip ======================================= */
[Tooltip]{
  position: relative;
}
[Tooltip]:hover::after{
  position: absolute;
  bottom: 120%;
  color: v-bind('$store.getters.Theme.BG5Fixed');
  padding: 0.5em;
  border-radius: var(--rad3);
  content: attr(ToolTip);
  position: absolute;
  background: v-bind('$store.getters.Theme.color1');
  backdrop-filter: blur(0.8);
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: block;
  text-align: center;
  max-width: 15em;
  left: calc(-50% + 0.5em);
  width: max-content;
  z-index: 5000000;
  /* width: 100%; */
  /* height: 100%; */
}
[Tooltip]::after::before {
  position: absolute;
  content: 'ssssssss';
  background: red;
  width: 2em;
  height: 2em;
}
/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Search */
.CookiesSearchInput{
  background: v-bind('$store.getters.Theme.Light3');
}
.CookiesSearchInput::placeholder{
  color: v-bind('$store.getters.Theme.Shade3');
}
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Search */
  .BigContainer{
    font-size: 0.9em;
    background: v-bind('$store.getters.Theme.BG2');
    border-radius: var(--rad1);
    filter: drop-shadow(var(--shadow1));
    width: 100%;
    /* height: 100%; */
    justify-content: center;
    text-align: center;
    /* display: inline-table; */
    margin: auto;
    display: flex;
    flex-direction: column;
  }
.CookiesGeneralTableRow{
  font-size: 1.1em;
  position: relative;
}
.CookiesGeneralTableRow:nth-child(even){
  background-color: var(--shade0);
}
.CookiesGeneralTableRow:hover{
  /* background: #ffffff; */
  /* background: v-bind('$store.getters.Theme.BG6'); */
  /* cursor: pointer; */
  /* filter: drop-shadow(var(--shadow1)); */
  z-index: 2;
}
.CookiesGeneralTableRow::after {
  opacity: 0;
  background: v-bind('$store.getters.Theme.BG2');
  background-size: 100vw;
  filter: drop-shadow(var(--shadow1));
  content: '';
  /* background: red; */
  border-radius: var(--rad3);
  position: absolute;
  inset: 0;
  z-index: -1;
  transition: opacity 0.5s ease;
}
.CookiesGeneralTableRow:hover::after {
  opacity: 1;
}
.CookiesGeneralTable td, th{
  white-space: nowrap;
  padding: 1em 0.3em;
  /* display: inline-flex; */
  margin: auto;
  max-width: 23%;
}
.CookiesGeneralTable td{
  border-left: v-bind('DirectionStyler.borderLeft');
  border-Right: v-bind('DirectionStyler.borderRight');
}
.CookiesGeneralTable tr{
  width: 100%;
}
.CookiesGeneralTable tr:first-child{
  filter: drop-shadow(var(--shadow0));
  z-index: 1;
  position: relative;
  width: 100%;
}
.CookiesGeneralTable th::after{
  content: '';
  background: v-bind('$store.getters.Theme.BG2');
  z-index: -55;
  inset: 0;
  position: absolute;
}
.CookiesGeneralTable th::before{
  content: '';
  background: v-bind('TabHeaderShadower');
  z-index: -70;
  inset: 0;
  position: absolute;
}
.CookiesGeneralTable th{
  position: sticky;
  top: 0;
  z-index: 555;
  background: v-bind('CurrentBackgroundColor');
  background-attachment: fixed ;
}
.CookiesGeneralTable { border-collapse: 0px; }
.CookiesGeneralTable td:first-child { border-radius: v-bind('DirectionStyler.first'); border: none !important;}
.CookiesGeneralTable td:nth-last-child(1) { border-radius: v-bind('DirectionStyler.last'); }
.CookiesGeneralTable th:first-child { border: none !important;}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover{
    background: #ff8d00;
    font-weight: normal;
    color: v-bind('$store.getters.Theme.BG2');
}
.CookiesCopyrights{
  z-index: 3000;
  justify-content: center;
  width: 100%;
  display: flex;
  position: absolute;
  height: 2em;
  bottom: 5em;
  /* background: linear-gradient(90deg, #00000000, v-bind('$store.getters.Theme.BG3')); */
  font-size: 0.8em;
}
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: v-bind('$store.getters.Theme.Shade3');
  }
  ::-webkit-scrollbar-thumb {
    background: v-bind('$store.getters.Theme.Shade1');
  }
@page{
  size: A5 portrait;
}

.CookiesFade{
  transform-style: preserve-3d;
  animation: CookiesFadeIn 0.3s;
}
@keyframes CookiesFadeIn {
  0%{
    opacity: 0;
    transform: scale(0.94) rotateX(20deg);
  }
  100%{
    opacity: 1;
  }
}
.SearchBarIcon{
  inset: 0;
  display: flex;
  margin-right: -4em;
  margin-left: 2em;
}
</style>
