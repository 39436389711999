<template>
<div v-if="Access('CustomersView')" class="CookiesMainView">
  <div class="BigPlaceholder">
    <div class="BigContainer">
      <div class="BigHeader">
        <div class="D-Flex">
          <span class="F-Bold F1_75em BigHeaderText">{{LanguageSelector('Customers', 'العملاء')}}</span>
          <button v-if="Access('CustomersAdd')" @click="CookiesAddCustomer()" class="DialogHeaderButton F1_25em BGThird">+ {{LanguageSelector('New Customer', 'عميل جديد')}}</button>
        </div>
        <div class="D-Flex M-Auto CookiesSearchInputContainer" :style="$store.getters.Direction === 'ltr' ? 'margin-right: 0em;': 'margin-left: 0em;'">
          <input v-model="Search" class="CookiesSearchInput color2 F1_25em" :placeholder="LanguageSelector('Search...', 'بحث...')">
        </div>
      </div>
        <div class="BigBody P-Rel">
          <div v-if="Loading" class="P-Abs D-Flex W-100" style="height: 5em;">
            <div class="M-Auto">
              <CookiesLoading></CookiesLoading>
            </div>
          </div>
          <div class="TableBodyContainer">
            <div class=" CookiesRow">
              <table v-if="FilterdTable" class="W-100 CookiesGeneralTable" CELLSPACING=0> <!-- TABLE --------------------- -->
                <tr>
                  <th></th>
                  <CookiesSortingTH @click="SortTable(['Company'])" :Name="LanguageSelector('Company', 'الشركة')" :Value="['Company']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <CookiesSortingTH @click="SortTable(['Name'])" :Name="LanguageSelector('Name', 'الاسم')" :Value="['Name']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <CookiesSortingTH @click="SortTable(['PhoneNumber'])" :Name="LanguageSelector('Phone', 'الرقم')" :Value="['PhoneNumber']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <CookiesSortingTH @click="SortTable(['Balance', 'Credited'])" :Name="LanguageSelector('Balance In', 'المستحقات')" :Value="['Balance', 'Credited']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <CookiesSortingTH @click="SortTable(['Balance', 'Debited'])" :Name="LanguageSelector('Balance Out', 'المصاريف')" :Value="['Balance', 'Debited']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <CookiesSortingTH @click="SortTable(['Balance', 'NetBalance'])" :Name="LanguageSelector('Net Balance', 'الاجمالي')" :Value="['Balance', 'NetBalance']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <th></th>
                </tr>
                <tr v-if="!Loading & FilterdTable.length === 0"><td colspan="10" style="padding: 1.5em 0.2em;">{{LanguageSelector('No Customers', 'لا يوجد عملاء')}}</td></tr>
                <tr v-for='(value, index) in FilterdTable' :key='(value)' class="CookiesCustomerRow CookiesGeneralTableRow">
                  <td style="padding: 1em;">{{index + 1}}</td>
                  <td>{{value['Company'] ? value['Company']: 'N/A'}}</td>
                  <td>{{value['Name'] ? value['Name']: 'N/A'}}</td>
                  <td class="RowNoClick Pointer HoverGlow" @click="OpenWhatsapp(value['PhoneNumber'])">{{value['PhoneNumber'] ? value['PhoneNumber']: 'N/A'}}</td>
                  <td><span>{{DeepFetcher(CashLedger(value['Balance']), ['Credited']) ? DeepFetcher(CashLedger(value['Balance']), ['Credited']): '0'}}</span></td>
                  <td><span>{{DeepFetcher(CashLedger(value['Balance']), ['Debited']) ? DeepFetcher(CashLedger(value['Balance']), ['Debited']): '0'}}</span></td>
                  <td class="F-Bold">
                    <span> {{ DeepFetcher(CashLedger(value['Balance']), ['NetBalance']) ? DeepFetcher(CashLedger(value['Balance']), ['NetBalance']) : '0' }} </span><span> {{DeepFetcher(CashLedger(value['Balance']), ['NetBalance']) !== 0 ? ' ' + $store.state.CookiesConfig.PriceUnit + ' ': ''}} </span>
                    <CookiesUpDownArrow v-if="DeepFetcher(CashLedger(value['Balance']), ['NetBalance']) !== 0" :Value="DeepFetcher(CashLedger(value['Balance']), ['NetBalance']) ? DeepFetcher(CashLedger(value['Balance']), ['NetBalance']) : 0" style="display: inline-block; padding: 0.1em 0.5em;"></CookiesUpDownArrow>
                  </td>
                  <td style="padding: 0.6em 0.4em">
                    <CookiesIcon :ToolTip="LanguageSelector('Payments', 'الدفعات')" class="CookiesRowIcon RowNoClick HoverGlow" v-if="Access('FinancialRead')" @click="CustomerBalance(value)" :ID="'Compensate'"></CookiesIcon>
                    <CookiesIcon :ToolTip="LanguageSelector('Returned Items', 'المرتجعات')" class="CookiesRowIcon RowNoClick HoverGlow" v-if="Access('FinancialRead')" @click="CustomerReturns(value)" :ID="'Recover'"></CookiesIcon>
                    <CookiesIcon :ToolTip="LanguageSelector('Current Orders', 'الطلبات الحالية')" class="CookiesRowIcon RowNoClick HoverGlow" v-if="Access('FinancialRead')" @click="CustomerOrders(value)" :ID="'Received'"></CookiesIcon>
                    <CookiesIcon :ToolTip="LanguageSelector('Areas', 'المناطق')" class="CookiesRowIcon RowNoClick HoverGlow" v-if="Access('AreasView')" @click="OpenAreas(value)" :ID="'Location'"></CookiesIcon>
                    <CookiesIcon :ToolTip="LanguageSelector('Edit', 'تعديل')" class="CookiesRowIcon RowNoClick HoverGlow" v-if="Access('CustomersEdit')" @click="OpenCustomer(value)" :ID="'Edit'"></CookiesIcon>
                    <CookiesIcon :ToolTip="LanguageSelector('Delete', 'حذف')" class="CookiesRowIcon RowNoClick HoverGlow" v-if="Access('CustomersDelete')" :class="DeepFetcher(CashLedger(value['Balance']), ['Debited']) || DeepFetcher(CashLedger(value['Balance']), ['Credited']) ? 'IconDisabled' : ''" @click="DeepFetcher(CashLedger(value['Balance']), ['Debited']) || DeepFetcher(CashLedger(value['Balance']), ['Credited']) ? RemoveCustomer(value) : RemoveCustomer(value)" :ID="'Delete'"></CookiesIcon>
                  </td>
                </tr>
              </table> <!-- TABLE END --------------------- -->
            </div>
          </div>
        </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  mounted () {
    this.ReadCustomers()
  },
  data () {
    return {
      CookiesDataCustomers: [],
      Search: '',
      sortElment: {
        name: ['ID'],
        value: -1
      },
      Loading: false
    }
  },
  computed: {
    FilterdTable () {
      this.$forceUpdate()
      var FilteredArray = []
      this.sortedCustomers.forEach(item => {
        var FilterIt = true
        // Search ///////////////////////////////////////////////////////////////////////////////////////////////////
        var SearchIt = this.CookiesSearcher(item, this.Search)
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        if (SearchIt && FilterIt) {
          FilteredArray.push(item)
        }
      })
      return FilteredArray
    },
    sortedCustomers () {
      var Sorted = this.CookiesDataCustomers
      return Sorted.sort(this.Sorting)
    },
    CurrentDialogs () {
      return this.$store.getters.CurrentDialogsLength
    },
    CurrentDate () {
      return this.$store.state.CurrentDate
    }
  },
  methods: {
    ReadCustomers () {
      this.Loading = true
      this.Get('Customers').then(response => {
        this.CookiesDataCustomers = response.map(item => {
          this.DeepChanger(item, ['Balance', 'Credited'], this.DeepFetcher(this.CashLedger(item.Balance), ['Credited']))
          this.DeepChanger(item, ['Balance', 'Debited'], this.DeepFetcher(this.CashLedger(item.Balance), ['Debited']))
          this.DeepChanger(item, ['Balance', 'NetBalance'], this.DeepFetcher(this.CashLedger(item.Balance), ['Credited']) - this.DeepFetcher(this.CashLedger(item.Balance), ['Debited']))
          return item
        })
        this.Loading = false
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Customers'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    CustomerBalance (Customer) {
      this.OpenDialog('CustomerBalance', this.LanguageSelector('Customer Account Statement', 'كشف حساب العميل'), Customer) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    CustomerReturns (Customer) {
      this.OpenDialog('CustomerReturns', this.LanguageSelector('Customer Returns', 'مرتجعات العميل'), Customer) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    CustomerOrders (Customer) {
      this.OpenDialog('CustomerOrders', this.LanguageSelector('Customer Current Orders', 'الطلبات الحالية للعميل'), Customer) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    CookiesAddCustomer () {
      this.OpenDialog('NewCustomer', this.LanguageSelector('New Customer', 'عميل جديد'), '') // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    OpenCustomer (Customer) {
      this.OpenDialog('EditCustomerDialog', this.LanguageSelector('Edit Customer (' + Customer.Name + ')', 'تعديل العميل (' + Customer.Name + ')'), Customer) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    OpenAreas (Customer) {
      this.OpenDialog('CustomerAreas', this.LanguageSelector('Customer Areas (' + Customer.Name + ')', 'مناطق العميل (' + Customer.Name + ')'), Customer) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    Sorting (a, b) {
      try {
        if (this.DeepFetcher(a, this.sortElment.name) > this.DeepFetcher(b, this.sortElment.name)) {
          return this.sortElment.value
        }
        if (this.DeepFetcher(a, this.sortElment.name) < this.DeepFetcher(b, this.sortElment.name)) {
          return this.sortElment.value * -1
        }
        return 0
      } catch (error) {
        return 0
      }
    },
    SortTable (name) {
      if (JSON.stringify(this.sortElment.name) === JSON.stringify(name)) {
        this.sortElment.value = this.sortElment.value * -1
      } else {
        this.$forceUpdate()
        this.sortElment.name = name
      }
    },
    RemoveCustomer (Customer) {
      this.OpenDialog('DeleteDialog', 'Deleting Customer', ['Customer/DeleteCustomer?Id=' + Customer.Id, Customer.Name]) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    CashLedger (Balance) {
      var CashLedger = {
        Credited: 0,
        Debited: 0
      }
      try {
        var AllLedgers = this.DeepFetcher(Balance, ['ledgers'])
        if (AllLedgers == null) { return }
        if (this.ObjectFinder(AllLedgers, ['Type'], 17)) {
          CashLedger = this.ObjectFinder(AllLedgers, ['Type'], 17)
          CashLedger.NetBalance = CashLedger.Credited - CashLedger.Debited
        }
      } catch (error) {
      }
      return CashLedger
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCustomers()
      }
    }
  }
}
</script>
<style scoped>

.BigHeader{
  font-size: 0.9em;
}
.BigBody{
  font-size: 0.85rem;
}
@media (max-width: 500px) {
  .BigHeader>>>*{
    font-size: 1.6em !important;
  }
  .StatusBar>>>*{
    font-size: 1.3em !important;
  }
}
@media (max-width: 1400px) {
  .BigPlaceholder>>>*{
    font-size: 1.2vw;
  }
}
@media (max-width: 1200px) {
  .BigPlaceholder>>>*{
    font-size: 1vw;
  }
  .FilterPlaceholder{
    font-size: 15px;
  }
}

</style>
